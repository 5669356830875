import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

import Divider from '@mui/material/Divider';

import lajos from '../../assets/lajos/lajos.jpg';
import andrea from '../../assets/andrea/andrea.jpg';
import tamas from '../../assets/tamas/tamas_1_4.jpg';
import testverek from '../../assets/AboutUs/testverek3_500x350.jpg';
import testverek2 from '../../assets/AboutUs/testverek2.jpg';
import testverek3 from '../../assets/AboutUs/testverek1.jpg';
import testverek4 from '../../assets/AboutUs/testverek5.jpg';
import testverek5 from '../../assets/AboutUs/testverek4.jpg';
import video from '../../assets/AboutUs/gyerekek.mp4';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='paragraph' style={{ marginTop: 24 }}>
        <div className='content'>
          <figure>
            <img
              className='us'
              src={testverek}
              alt='The three sibling'
              width='500'
              height='350'
            />
            <figcaption>A három testvér (Lajos, Andrea, Tamás)</figcaption>
          </figure>
          {/* <span>
            <h2>Kapcsándi család</h2>
            <div>Leírás</div>
          </span> */}
        </div>
      </div>
      <Divider role='presentation' style={{ marginBottom: 24, marginTop: 24 }}>
        Testvérek
      </Divider>
      <div className='persons'>
        <NavLink to='/Lajos'>
          <img className='siblings' src={lajos} alt='Lajos' width='200' height='300' />
        </NavLink>
        <NavLink to='/Tamas'>
          <img className='siblings' src={tamas} alt='Tamás' width='200' height='300' />
        </NavLink>
        <NavLink to='/Andrea'>
          <img className='siblings' src={andrea} alt='Andrea' width='200' height='300' />
        </NavLink>
      </div>
      <Divider role='presentation' style={{ marginBottom: 24, marginTop: 24 }}>
        Képek
      </Divider>
      <div className='family_pics'>
        <figure>
          <img
            src={testverek4}
            alt='Kapcsándi family'
            width='375'
            height='262'
          />
          <figcaption hidden>Kapcsándi családi kép 3</figcaption>
        </figure>
        <figure>
          <img
            src={testverek3}
            alt='Kapcsándi family'
            width='375'
            height='262'
          />
          <figcaption hidden>Kapcsándi családi kép 2</figcaption>
        </figure>
        <figure>
          <img
            src={testverek2}
            alt='Kapcsándi family'
            width='375'
            height='262'
          />
          <figcaption hidden>Kapcsándi családi kép 1</figcaption>
        </figure>
        <figure>
          <img
            src={testverek5}
            alt='Kapcsándi family'
            width='375'
            height='262'
          />
          <figcaption hidden>Kapcsándi családi kép 4</figcaption>
        </figure>
      </div>
      <Divider role='presentation' style={{ marginBottom: 24, marginTop: 24 }}>
        Videók
      </Divider>
      <div className='vids'>
        <figure className='video'>
          <video src={video} width='550' height='300' controls></video>
        </figure>
      </div>
    </>
  );
};

export default AboutUs;
