import { useLocation } from 'react-router-dom';

import Header from './Header';
import MenuBar from '../Layouts/MenuBar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const pathLocation = useLocation();

  return (
    <>
      {pathLocation.pathname === '/Kozmetika' ? <Header /> : <MenuBar />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
