import { useEffect, useState } from 'react';
import axios from 'axios';
// import Calendar from './Calendar';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const AppointmentForm = () => {
  const careTypes = [
    { name: 'alma', time: 120 },
    { name: 'fa', time: 60 },
    { name: 'korte', time: 30 },
  ];

  const [userName, setUsername] = useState('');
  const [careType, setCareType] = useState(careTypes[0].name);
  const [careLength, setCareLength] = useState(careTypes[0].time);
  const [datetime, setDatetime] = useState('');
  const [generatedId, setGeneratedId] = useState('');

  useEffect(() => {
    setGeneratedId((Math.random() + 1).toString(36).substring(2));
  }, []);

  const createUser = async (e) => {
    e.preventDefault();

    const appointmentDate = new Date(datetime);
    console.log(appointmentDate);
    try {
      await axios.post('http://localhost:4000/cosmetic', {
        generatedId,
        userName,
        careType,
        careLength,
        appointmentDate,
      });
    } catch (error) {
      console.log('We had an error: ' + error);
    }
  };

  const handleSelectChange = (e) => {
    setCareType(e.target.value);
    setCareLength(careTypes.find((type) => type.name === e.target.value).time);
  };

  const handleDateClick = (data) => {
    console.log('dateClicked: ');
    console.log(data);
  };

  return (
    <div className='appointmentFormContainer'>
      {/* <Calendar reservedTime={datetime} /> */}
      <div className='calendar'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          initialView='dayGridMonth'
          weekends={false}
          events={[
            { title: 'event 0', date: '2022-09-27' },
            { title: 'event 1', date: '2022-09-27' },
            { title: 'event 2', date: '2022-09-28' },
          ]}
          dateClick={handleDateClick}
        />
      </div>
      <div className='appointmentForm'>
        <h2>Foglalás</h2>
        <form onSubmit={createUser} className='formSubmission'>
          <label htmlFor='userName'>Keresztnév: </label>
          <input
            name='userName'
            id='userName'
            type='text'
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor='careType'>Típus: </label>
          <select
            name='careType'
            id='careType'
            value={careType}
            onChange={handleSelectChange}
          >
            {careTypes.map((type) => (
              <option value={type.name} key={type.name}>
                {type.name}
              </option>
            ))}
          </select>
          <div>Kezelés hossza: {careLength}</div>
          <label>Időpont: </label>
          <input
            type='datetime-local'
            min='2020-01-01T00:00'
            max='2030-12-31T13:59'
            value={datetime}
            onChange={(e) => setDatetime(e.target.value)}
          />
          <button type='submit'>Időpont foglalása</button>
        </form>
      </div>
    </div>
  );
};

export default AppointmentForm;
