import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='paragraph'>
        <span className='paragraphTitle'>
          <h1>Üdvözöllek az oldalunkon!</h1>
          <h3>Kérlek kattints az egyes fejlécekre, hogy megismerj minket!</h3>
        </span>
      </div>
    </>
  );
};

export default Home;
