import { useEffect } from 'react';

import Divider from '@mui/material/Divider';

import lajosPic from '../../assets/lajos/lajos_1_400x600.jpg';
import lajosPicFamily1 from '../../assets/lajos/family1.jpg';
import lajosPicFamily2 from '../../assets/lajos/family2.jpg';

const Lajos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='paragraph'>
        <span>
          <h1>Üdvözöllek Lajos oldalán!</h1>
        </span>
        <div className='content'>
          <figure>
            <img
              className='mainPic'
              src={lajosPic}
              alt='Kapcsándi Lajos'
              width='400'
              height='600'
            />
            <figcaption hidden>Kapcsándi Lajos</figcaption>
          </figure>
          <span className='bioContainer'>
            <h2 className='bioHeader'>Lajos bio</h2>
            <div className='descBio'>
              A nevem Kapcsándi Lajos a Kapcsándi Testvérek legidősebb tagja
              vagyok. Feleségemmel és gyermekemmel élek a budapesti
              agglomerációban, ahonnan alkalomadtán bemegyek Budapestre
              dolgozni. Otthon a játék a mindenünk, amiben a kiskutyánk is
              fontos szerepet vállal. Szabadidőnkben szívesen megyünk
              kirándulni, és nagyon szeretjük a családi nyaralásokat is. A
              munkámban mindenféle érdekes dologgal foglalkozom, de a legjobban
              azt élvezem hogy emberekkel dolgozhatok. Igazán szerencsésnek
              mondhatom magam mert a csapatom igazán csodálatos, leginkább úgy
              érzem hogy ők azok akik miatt érdemes bejárni.
            </div>
          </span>
        </div>
      </div>
      <div className='paragraph2'>
        <div className='desc'>
          <Divider role='presentation'>
            <h2 className='familyHeader'>Lajos családja</h2>
          </Divider>
        </div>
        <div className='imagesContainer' id='imagesContainer'>
          <figure>
            <img
              src={lajosPicFamily1}
              alt='Lajos family'
              width='350'
              height='200'
            />
            <figcaption hidden>Lajos családi kép 1</figcaption>
          </figure>
          <figure>
            <img
              src={lajosPicFamily2}
              alt='Lajos family'
              width='350'
              height='200'
            />
            <figcaption hidden>Lajos családi kép 2</figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};

export default Lajos;
