import { useEffect } from 'react';

import Divider from '@mui/material/Divider';

import andreaPic from '../../assets/andrea/andrea_400x600.jpg';
import andreaPicFamily1 from '../../assets/andrea/family1.jpg';
import andreaPicFamily2 from '../../assets/andrea/family2.jpg';

const Andrea = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='paragraph'>
        <span className='paragraphTitle'>
          <h1>Üdvözöllek Andrea oldalán!</h1>
        </span>
        <div className='content'>
          <figure>
            <img
              className='mainPic'
              src={andreaPic}
              alt='Kapcsándi Andrea'
              width='400'
              height='600'
            />
            <figcaption hidden>Kapcsándi Andrea</figcaption>
          </figure>
          <span className='bioContainer'>
            <h2 className='bioHeader'>Andrea bio</h2>
            <div className='descBio'>
              Szeretettel üdvözöllek! 😊 Kapcsándi Andrea vagyok, 2 csodálatos
              gyermek anyukája, kozmetikus mester. Boldog vagyok, hogy
              megtaláltam azt a szakmát, amiben kiteljesedhetek. A munkát, amit
              szeretek. „Nem a siker a kulcs a boldogsághoz. A boldogság a kulcs
              a sikerhez. Ha szereted azt, amit csinálsz, sikeres leszel.” –
              Albert Schweitzer A szépségipar az egyik legdinamikusabban fejlődő
              iparág, ezért fontosnak tartom, hogy mindig napra kész legyek az
              új kozmetikai eljárásokkal, innovatív hatóanyagokkal kapcsolatban.
              Rendszeresen részt veszek különféle tanfolyamokon,
              továbbképzéseken, kiállításokon, hogy igényes, személyre szabott
              és hatékony professzionális kezeléseket tudjak biztosítani, minden
              kedves vendégemnek Gödöllőn, a saját kozmetikai szalonomban.
              Szabadidőmet a családomnak szentelem... (természetesen ide
              tartozik a két kutyusunk 🐕 és a két nyuszink 🐇 is, hisz ők is
              teljes értékű családtagok.)
            </div>
          </span>
        </div>
      </div>
      <div className='paragraph2'>
        <div className='desc'>
          <Divider role='presentation'>
            <h2 className='familyHeader'>Andrea családja</h2>
          </Divider>
        </div>
        <div className='imagesContainer' id='imagesContainer'>
          <figure>
            <img
              src={andreaPicFamily1}
              alt='Andrea family'
              width='350'
              height='200'
            />
            <figcaption hidden>Andrea családi kép 1</figcaption>
          </figure>
          <figure>
            <img
              src={andreaPicFamily2}
              alt='Andrea family'
              width='350'
              height='200'
            />
            <figcaption hidden>Andrea családi kép 2</figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};

export default Andrea;
