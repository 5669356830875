import { useEffect } from 'react';

import Divider from '@mui/material/Divider';

import tamasPic from '../../assets/tamas/tamas_3_1_400x600.jpg';
import tamasPicFamily1 from '../../assets/tamas/tamas_family_6_350x200.jpg';
import tamasPicFamily2 from '../../assets/tamas/tamas_family_5_350x200.jpg';

const Tamas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='paragraph'>
        <span>
          <h1>Üdvözöllek Tamás oldalán!</h1>
        </span>
        <div className='content'>
          <figure>
            <img
              className='mainPic'
              src={tamasPic}
              alt='Kapcsándi Tamas'
              width='400'
              height='600'
            />
            <figcaption hidden>Kapcsándi Tamás</figcaption>
          </figure>
          <span className='bioContainer'>
            <h2 className='bioHeader'>Tamás bio</h2>
            <div className='descBio'>
              Üdvözöllek, Kapcsándi Tamás vagyok a testvérek közül a középső.
              Gödöllőről származom, de jelenleg Budapesten élek és dolgozom.
              Napközben információ biztonsági területen dolgozom, esténként
              pedig automatizálás, web- és multiplatform applikáció
              fejlesztéssel foglalkozom. Több mint 5 éve dolgozom az
              adatszivárgás megelőzésével, így számomra otthon és a
              fejlesztésben is fontos az adatbiztonság. Egyetemre a SZIE
              Gépészmérnöki Karára - Gödöllőn és Budapesten az ELTE Informatikai
              Karára jártam. Mivel mind az adatbiztonság, mind az applikáció
              fejlesztés fontos számomra, ezért ezt a két tudást egyesítve
              erősítem a jelenlegi csapatomat és ebben a tudatban fejlesztem a
              különböző applikációkat is. Célom, hogy családomnak biztonságos
              élete legyen így ne kelljen aggódni a különböző adatlopásokkal és
              csalásokkal szemben, emellett szeretném azt, hogy az applikációk,
              melyeket fejlesztek a lehető legbiztonságosabbak legyenek! Munkán
              kívül szeretek sportolni és mindenféle társasjátékokkal játszani.
            </div>
          </span>
        </div>
      </div>
      <div className='paragraph2'>
        <div className='desc'>
          <Divider role='presentation'>
            <h2 className='familyHeader'>Tamás családja</h2>
          </Divider>
        </div>
        <div className='imagesContainer' id='imagesContainer'>
          <figure>
            <img
              src={tamasPicFamily1}
              alt='Tamas family'
              width='350'
              height='200'
            />
            <figcaption hidden>Tamás családi kép 1</figcaption>
          </figure>
          <figure>
            <img
              src={tamasPicFamily2}
              alt='Tamas family'
              width='350'
              height='200'
            />
            <figcaption hidden>Tamás családi kép 2</figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};

export default Tamas;
