import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import Layout from './view/Layouts/Layout';
import Home from './view/Home/Home';
import Andrea from './view/Andrea/Andrea';
import Lajos from './view/Lajos/Lajos';
import Tamas from './view/Tamas/Tamas';
import AboutUs from './view/AboutUs/AboutUs';
import Cosmetic from './view/Cosmetic/Cosmetic';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='Andrea' element={<Andrea />} />
          <Route path='Lajos' element={<Lajos />} />
          <Route path='Tamas' element={<Tamas />} />
          <Route path='Rolunk' element={<AboutUs />} />
          <Route path='Kozmetika' element={<Cosmetic />} />
          <Route
            path='/404'
            element={
              <div>
                <h1>404</h1>Page not found
              </div>
            }
          />
          <Route path='*' element={<Navigate replace to='/404' />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
