import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import FaceIcon from '@mui/icons-material/Face';
import Face3Icon from '@mui/icons-material/Face3';
import GroupsIcon from '@mui/icons-material/Groups';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

const MenuBar = () => {
  const pages = ['Home', 'Andrea', 'Lajos', 'Tamás', 'Rólunk'];
  const [anchorElNav, setAnchorElNav] = useState();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position='sticky'
      elevation={1}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ flexWrap: 'wrap' }}>
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{
              flexGrow: 1,
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            kapcsandi.hu
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <NavLink
                  key={page}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to={
                    page === 'Tamás'
                      ? '/Tamas'
                      : page === 'Home' || page === 'Rólunk'
                      ? page === 'Rólunk'
                        ? '/Rolunk'
                        : '/'
                      : `/${page}`
                  }
                >
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    style={{ display: 'flex' }}
                  >
                    {page === 'Home' && <HomeIcon />}
                    {page === 'Andrea' && <Face3Icon />}
                    {page === 'Lajos' && <FaceIcon />}
                    {page === 'Tamás' && <FaceIcon />}
                    {page === 'Rólunk' && <GroupsIcon />}
                    <Typography textAlign='center'>{page}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
          <Typography
            variant='h5'
            noWrap
            component='a'
            href=''
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            kapcsandi.hu
          </Typography>
          <nav>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <div
                  key={page}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  {page !== 'Home' && (
                    <hr
                      style={{
                        height: '50px',
                        marginRight: 30,
                      }}
                    />
                  )}
                  <NavLink
                    className='menuButton'
                    style={({isActive}) => ({
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'white',
                      opacity: isActive && 0.75, 
                    })}
                    to={
                      page === 'Tamás'
                        ? '/Tamas'
                        : page === 'Home' || page === 'Rólunk'
                        ? page === 'Rólunk'
                          ? '/Rolunk'
                          : '/'
                        : `/${page}`
                    }
                    
                  >
                    {page === 'Home' && <HomeIcon />}
                    {page === 'Andrea' && <Face3Icon />}
                    {page === 'Lajos' && <FaceIcon />}
                    {page === 'Tamás' && <FaceIcon />}
                    {page === 'Rólunk' && <GroupsIcon />}
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        textDecoration: 'none',
                      }}
                    >
                      {page}
                    </Button>
                  </NavLink>
                </div>
              ))}
            </Box>
          </nav>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MenuBar;
