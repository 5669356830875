import { useEffect } from 'react';
import AppointmentForm from '../../components/AppointmentForm';

const Cosmetic = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h1>Kapcsándi Andrea Kozmetika</h1>
      <AppointmentForm />
    </>
  );
};

export default Cosmetic;
